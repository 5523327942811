/*
 * This is the "core" webpack which should contain modules used throughout the
 * entire project. Be careful of adding modules which are intrusive by default,
 * such as UI frameworks; stuff imported here should be opt-in as much as
 * possible.
 */

// ActionCable
import "channels"

// Turbo (disable Turbo Drive by default -- https://github.com/hotwired/turbo/pull/196)
import { Turbo } from "@hotwired/turbo-rails"
Turbo.navigator.delegate.elementIsNavigable = function(element) {
  const container = element === null || element === void 0 ? void 0 : element.closest("[data-turbo]")
  if (container) {
    return container.getAttribute("data-turbo") == "true"
  } else {
    return false
  }
}

// Stimulus
import "stimulus_controllers"
